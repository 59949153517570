import React from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'

const Booking = ({ onChange, selected }) => {
  const { t } = useTranslation()

  const { options, isLoading, allowFetch } = useSelectOptionsData({
    dataType: workflowDataTypes.booking,
    defaultOptions: typeof selected === 'object' &&
      'id' in selected && [selected],
    fetchImmediately: !!selected,
  })

  const selectedOption =
    typeof selected === 'number' ? { id: selected } : selected

  return (
    <Select
      id="workflow-booking-select"
      onChange={onChange}
      options={options}
      maxMenuHeight={200}
      placeholder={t('workflow.selectors.placeholder')}
      noOptionsMessage={() => t('workflow.selectors.no_options')}
      value={options.find(option => option.value === selectedOption?.id)}
      onFocus={allowFetch}
      isLoading={isLoading}
    />
  )
}

export default Booking
