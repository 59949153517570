import React from 'react'
import Group from 'shared/components/Group'
import Booking from 'workflow/components/Booking'

const TriggerTypeBooking = ({ change, data }) => {
  const handleChange = ({ value, label }) => {
    change({ ...data, id: value, name: label })
  }

  return (
    <Group data-testid="workflow-step-trigger-booking">
      <Booking onChange={handleChange} selected={data} />
    </Group>
  )
}

export default TriggerTypeBooking
