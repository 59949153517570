export const workflowDataTypes = {
  booking: 'booking',
  contactFields: 'contactFields',
  tags: 'tags',
  campaigns: 'campaigns',
  courses: 'courses',
  courseModules: 'courseModules',
  moduleLectures: 'moduleLectures',
  communities: 'communities',
  courseBundles: 'courseBundles',
  funnels: 'funnels',
  optInSteps: 'optInSteps',
  saleSteps: 'saleSteps',
  webinarRegistrationSteps: 'webinarRegistrationSteps',
  funnelSteps: 'funnelSteps',
  emails: 'emails',
  mailings: 'mailings',
  emailMessagesWithRecipient: 'emailMessagesWithRecipient',
  blogs: 'blogs',
  blogPages: 'blogPages',
  blogPosts: 'blogPosts',
  emailLinks: 'emailLinks',
  drives: 'drives',
  spreadsheets: 'spreadsheets',
  worksheet: 'worksheet',
  triggerMappingSamples: 'triggerMappingSamples',
  sheetColumns: 'sheetColumns',
  crmPipelines: 'crmPipelines',
  digitalStoreOptIns: 'digitalStoreOptIns',
  digitalStores: 'digitalStores',
}

export const apiUrlByDataType = {
  booking: 'api/dashboard/booking-calendar/event/my-events',
  contactFields: '/api/dashboard/customer/contact/fields/list',
  tags: '/api/dashboard/customer/contact/tags/list-all',
  campaigns: '/api/dashboard/customer/mailing/campaigns/list-all',
  courses: '/api/dashboard/customer/membership/courses/list-all',
  courseModules: courseId =>
    `/api/dashboard/customer/membership/course/${courseId}/curriculum`,
  moduleLectures: moduleId =>
    `/api/dashboard/customer/membership/courses/modules/${moduleId}/lectures/list-all`,
  communities: '/api/dashboard/community/community',
  courseBundles: '/api/dashboard/customer/membership/course-bundles/list-all',
  funnels: '/api/dashboard/customer/funnels/list-all',
  optInSteps: funnelId =>
    `/api/dashboard/customer/funnels/${funnelId}/steps/list-all-opt-in`,
  saleSteps: funnelId =>
    `/api/dashboard/customer/funnels/${funnelId}/steps/list-all-sale`,
  webinarRegistrationSteps: funnelId =>
    `/api/dashboard/customer/funnels/${funnelId}/steps/list-all-webinar-registration`,
  funnelSteps: funnelId =>
    `/api/dashboard/customer/funnels/${funnelId}/steps/list-all`,
  emails: '/api/dashboard/customer/emails/list-all',
  mailings: `/api/dashboard/customer/mailing/list-all`,
  emailMessagesWithRecipient: `/api/dashboard/customer/emails/automation-to-specific-address/messages-with-recipient/list-all`,
  blogs: '/api/dashboard/customer/blogs/list-all',
  blogPages: blogId => `/api/dashboard/customer/blogs/${blogId}/pages/list-all`,
  blogPosts: blogId => `/api/dashboard/customer/blogs/${blogId}/posts/list-all`,
  emailLinks: mailingId =>
    `/dashboard/workflow-builder/api/workflow/links/${mailingId}`,
  drives: `/api/dashboard/google-integration/integrations`,
  spreadsheets: driveId =>
    `/api/dashboard/google-integration/spreadsheets?integrationId=${driveId}`,
  worksheet: ({ spreadsheetId, driveId }) =>
    `/api/dashboard/google-integration/spreadsheets/${spreadsheetId}/sheets?integrationId=${driveId}`,
  triggerMappingSamples: workflowId =>
    `/dashboard/workflow-builder/api/workflow/${workflowId}/sample`,
  sheetColumns: ({ driveId, spreadsheetId, sheetId }) =>
    `/api/dashboard/google-integration/spreadsheets/${spreadsheetId}/sheets/${sheetId}/header?integrationId=${driveId}`,
  crmPipelines: '/api/dashboard/customer/crm/pipelines',
  digitalStores:
    '/api/dashboard/digital-store/digital-stores/choice-list?items.type=optin',
  digitalStoreOptIns: storeId =>
    `/api/dashboard/digital-store/digital-stores/${storeId}/item-opt-ins/choice-list`,
}
