import React from 'react'

function BookedMeetingIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m24.507 17.564c-3.034 0-5.493 2.459-5.493 5.493s2.459 5.493 5.493 5.493 5.493-2.459 5.493-5.493-2.459-5.493-5.493-5.493zm2.432 5.259-2.682 2.16c-.16.129-.36.199-.564.199-.034 0-.068-.002-.103-.006-.239-.027-.457-.149-.605-.339l-1.054-1.344c-.307-.392-.238-.957.152-1.264.393-.307.956-.238 1.264.152l.491.627 1.972-1.589c.388-.313.954-.249 1.266.137.312.388.251.955-.137 1.267z" />
      <path d="m17.514 23.058c0-3.856 3.137-6.993 6.993-6.993 1.281 0 2.479.352 3.513.956v-9.001c0-2.27-1.84-4.11-4.11-4.11h-2.16v-.46c0-.5-.41-.9-.9-.9-.5 0-.9.4-.9.9v.46h-10.77v-.46c0-.5-.4-.9-.9-.9s-.9.4-.9.9v.46h-2.17c-2.27 0-4.11 1.84-4.11 4.11v14.74c0 2.27 1.84 4.11 4.11 4.11h13.442c-.717-1.098-1.138-2.406-1.138-3.812zm3.517-10.814c.574 0 1.039.465 1.039 1.039s-.465 1.039-1.039 1.039-1.039-.465-1.039-1.039.465-1.039 1.039-1.039zm-12.937 7.739c-.574 0-1.039-.465-1.039-1.039s.465-1.039 1.039-1.039 1.039.465 1.039 1.039-.465 1.039-1.039 1.039zm0-5.661c-.574 0-1.039-.465-1.039-1.039s.465-1.039 1.039-1.039 1.039.465 1.039 1.039-.465 1.039-1.039 1.039zm6.469 5.661c-.574 0-1.039-.465-1.039-1.039s.465-1.039 1.039-1.039 1.039.465 1.039 1.039-.466 1.039-1.039 1.039zm0-5.661c-.574 0-1.039-.465-1.039-1.039s.465-1.039 1.039-1.039 1.039.465 1.039 1.039-.466 1.039-1.039 1.039z" />
    </svg>
  )
}

export default BookedMeetingIcon
