import { stepTypes } from 'workflow/components/Step/stepTypes'
import {
  actionTypes,
  actionTypesObjects,
} from 'workflow/components/Step/types/Action/actionTypes'
import {
  triggerTypesObjectExtensions,
  triggerTypesObjects,
} from 'workflow/components/Step/types/Trigger/triggerTypes'
import { delayType } from 'workflow/components/Step/types/Delay/delayTypes'
import { isExactDate } from 'workflow/components/Step/types/Delay/isExactDate'
import { triggerTypes } from 'workflow/components/Step/types/Trigger/triggerTypes'

function getNormalizedConditionGroups(data) {
  return data.conditionGroups.map(group => ({
    contact_filter_condition_models: group.contactFilterConditionModels.map(
      model => ({
        search_field: model.searchField,
        search_value: model.searchValue,
        compare_operation: model.compareOperation,
      }),
    ),
  }))
}

function getActionAdditionalPayload(stepData) {
  switch (stepData.type) {
    case actionTypes.enrollInCourse:
      return {
        course_access_type: stepData.course.courseAccessType,
        course_modules: stepData.course.courseModules?.map(({ id }) => id),
      }
    case actionTypes.sendWebhook:
      return {
        webhook_url: stepData.webhook.webhookUrl,
      }
    case actionTypes.enrollInCourseBundle:
      return {
        enrollment_access_type: stepData.courseBundle.enrollmentAccessType,
      }
    default:
      return {}
  }
}

function prepareDigitalStoreTriggerPayload(step) {
  return {
    type: step.data.type,
    digital_store: step.data.digitalStore.id,
    item_optin: step.data.digitalStore.optIn.id,
  }
}

function prepareTriggerPayload(step) {
  switch (step.data.type) {
    case triggerTypes.digitalStoreOptIn:
      return prepareDigitalStoreTriggerPayload(step)
    case triggerTypes.bookingCreated:
      return {
        type: step.data.type,
        booking_event_id: step.data[triggerTypesObjects[step.data.type]].id,
      }
    default: {
      const property = triggerTypesObjects[step.data.type]
      return {
        type: step.data.type,
        [property]: step.data[property].id,
        ...(triggerTypesObjectExtensions[step.data.type]?.reduce(
          (acc, next) => {
            acc[next] = step.data[property][next]
            return acc
          },
          {},
        ) ?? {}),
      }
    }
  }
}

function prepareStepPayload(step) {
  let payload
  let property
  switch (step.objectType) {
    case stepTypes.action:
      property = actionTypesObjects[step.data.type]
      const [localProperty, apiProperty] =
        typeof property === 'object'
          ? [property.local, property.api]
          : [property, property]
      payload = {
        type: step.data.type,
        [apiProperty]: step.data[localProperty].id,
        ...getActionAdditionalPayload(step.data),
      }
      break
    case stepTypes.condition:
      payload = {
        conditions_model: {
          condition_groups: getNormalizedConditionGroups(step.data),
        },
      }
      break
    case stepTypes.delay:
      if (isExactDate(step.data.type)) {
        payload = {
          date: step.data.date,
          type: delayType.date,
        }
      } else {
        payload = {
          unit: step.data.unit,
          duration: step.data.duration,
          type: delayType.fixed,
        }
      }
      break
    default:
      payload = {}
  }

  return payload
}

export const normalizeAddTrigger = (step, parentStepId, childType = null) => {
  return {
    new_workflow_step: {
      new_step: {
        [stepTypes.trigger]: prepareTriggerPayload(step),
      },
      parent_step: parentStepId,
      child_type: childType,
    },
  }
}

export const normalizeAddStep = (step, parentStepId, childType = null) => {
  return {
    new_workflow_step: {
      new_step: {
        [step.objectType]: prepareStepPayload(step),
      },
      parent_step: parentStepId,
      child_type: childType,
    },
  }
}

export const normalizeUpdateStep = step => {
  return {
    workflow_step: {
      [step.objectType]: prepareStepPayload(step),
    },
  }
}

export const normalizeUpdateTrigger = step => {
  return {
    workflow_step: {
      [stepTypes.trigger]: prepareTriggerPayload(step),
    },
  }
}
