export const triggerTypes = {
  bookingCreated: 'booking_created',
  tagAdded: 'tag_added',
  tagRemoved: 'tag_removed',
  funnelFormSubscribed: 'form_subscribed',
  blogFormSubscribed: 'blog_page_form_subscribed',
  campaignCompleted: 'campaign_completed',
  registeredToWebinar: 'registered_to_webinar',
  enrolledInCourse: 'enrolled_in_course',
  courseCompleted: 'course_completed',
  moduleCompleted: 'module_completed',
  lectureCompleted: 'lecture_completed',
  enrolledInCourseBundle: 'enrolled_in_course_bundle',
  newSale: 'new_sale',
  saleCanceled: 'sale_canceled',
  emailOpened: 'email_opened',
  emailLinkClicked: 'email_link_clicked',
  pageVisited: 'page_visited',
  enrolledInCommunity: 'enrolled_in_community',
  subscriptionPaymentFailed: 'subscription_payment_failed',
  digitalStoreOptIn: 'digital_store_form_subscribed',
}

export const triggerTypesObjects = {
  [triggerTypes.bookingCreated]: 'bookingEvent',
  [triggerTypes.tagAdded]: 'tag',
  [triggerTypes.tagRemoved]: 'tag',
  [triggerTypes.funnelFormSubscribed]: 'funnelStep',
  [triggerTypes.blogFormSubscribed]: 'page',
  [triggerTypes.registeredToWebinar]: 'funnelStep',
  [triggerTypes.campaignCompleted]: 'campaign',
  [triggerTypes.enrolledInCourse]: 'course',
  [triggerTypes.enrolledInCommunity]: 'community',
  [triggerTypes.newSale]: 'funnelStep',
  [triggerTypes.saleCanceled]: 'funnelStep',
  [triggerTypes.enrolledInCourseBundle]: 'courseBundle',
  [triggerTypes.emailLinkClicked]: 'mailing',
  [triggerTypes.pageVisited]: 'funnelStep',
  [triggerTypes.courseCompleted]: 'course',
  [triggerTypes.moduleCompleted]: 'course',
  [triggerTypes.lectureCompleted]: 'course',
  [triggerTypes.emailOpened]: 'mailing',
  [triggerTypes.subscriptionPaymentFailed]: 'funnelStep',
  [triggerTypes.digitalStoreOptIn]: 'digitalStore',
}

export const triggerTypesObjectExtensions = {
  [triggerTypes.emailLinkClicked]: ['link'],
}

export const triggerTypesLabels = {
  [triggerTypes.tagAdded]:
    'workflow.steps.types.trigger.types.labels.tag_added',
  [triggerTypes.bookingCreated]:
    'workflow.steps.types.trigger.types.labels.booking_added',
  [triggerTypes.tagRemoved]:
    'workflow.steps.types.trigger.types.labels.tag_removed',
  [triggerTypes.funnelFormSubscribed]:
    'workflow.steps.types.trigger.types.labels.form_subscribed',
  [triggerTypes.blogFormSubscribed]:
    'workflow.steps.types.trigger.types.labels.blog_page_form_subscribed',
  [triggerTypes.campaignCompleted]:
    'workflow.steps.types.trigger.types.labels.campaign_completed',
  [triggerTypes.registeredToWebinar]:
    'workflow.steps.types.trigger.types.labels.registered_to_webinar',
  [triggerTypes.enrolledInCourse]:
    'workflow.steps.types.trigger.types.labels.enrolled_in_course',
  [triggerTypes.enrolledInCourseBundle]:
    'workflow.steps.types.trigger.types.labels.enrolled_in_course_bundle',
  [triggerTypes.enrolledInCommunity]:
    'workflow.steps.types.trigger.types.labels.enrolled_in_community',
  [triggerTypes.newSale]: 'workflow.steps.types.trigger.types.labels.new_sale',
  [triggerTypes.saleCanceled]:
    'workflow.steps.types.trigger.types.labels.sale_canceled',
  [triggerTypes.emailLinkClicked]:
    'workflow.steps.types.trigger.types.labels.email_link_clicked',
  [triggerTypes.pageVisited]:
    'workflow.steps.types.trigger.types.labels.page_visited',
  [triggerTypes.courseCompleted]:
    'workflow.steps.types.trigger.types.labels.course_completed',
  [triggerTypes.moduleCompleted]:
    'workflow.steps.types.trigger.types.labels.module_completed',
  [triggerTypes.lectureCompleted]:
    'workflow.steps.types.trigger.types.labels.lecture_completed',
  [triggerTypes.emailOpened]:
    'workflow.steps.types.trigger.types.labels.email_opened',
  [triggerTypes.subscriptionPaymentFailed]:
    'workflow.steps.types.trigger.types.labels.subscription_payment_failed',
  [triggerTypes.digitalStoreOptIn]:
    'workflow.steps.types.trigger.types.labels.digital_store_form_subscribed',
}

export const triggerTypesNames = {
  [triggerTypes.tagAdded]: 'workflow.steps.types.trigger.types.names.tag_added',
  [triggerTypes.bookingCreated]:
    'workflow.steps.types.trigger.types.names.booking_added',
  [triggerTypes.tagRemoved]:
    'workflow.steps.types.trigger.types.names.tag_removed',
  [triggerTypes.funnelFormSubscribed]:
    'workflow.steps.types.trigger.types.names.form_subscribed',
  [triggerTypes.blogFormSubscribed]:
    'workflow.steps.types.trigger.types.names.form_subscribed',
  [triggerTypes.campaignCompleted]:
    'workflow.steps.types.trigger.types.names.campaign_completed',
  [triggerTypes.registeredToWebinar]:
    'workflow.steps.types.trigger.types.names.registered_to_webinar',
  [triggerTypes.enrolledInCourse]:
    'workflow.steps.types.trigger.types.names.enrolled_in_course',
  [triggerTypes.enrolledInCourseBundle]:
    'workflow.steps.types.trigger.types.names.enrolled_in_course_bundle',
  [triggerTypes.enrolledInCommunity]:
    'workflow.steps.types.trigger.types.names.enrolled_in_community',
  [triggerTypes.newSale]: 'workflow.steps.types.trigger.types.names.new_sale',
  [triggerTypes.saleCanceled]:
    'workflow.steps.types.trigger.types.names.sale_canceled',
  [triggerTypes.emailLinkClicked]:
    'workflow.steps.types.trigger.types.names.email_link_clicked',
  [triggerTypes.pageVisited]:
    'workflow.steps.types.trigger.types.names.page_visited',
  [triggerTypes.courseCompleted]:
    'workflow.steps.types.trigger.types.names.course_completed',
  [triggerTypes.moduleCompleted]:
    'workflow.steps.types.trigger.types.names.module_completed',
  [triggerTypes.lectureCompleted]:
    'workflow.steps.types.trigger.types.names.lecture_completed',
  [triggerTypes.emailOpened]:
    'workflow.steps.types.trigger.types.names.email_opened',
  [triggerTypes.subscriptionPaymentFailed]:
    'workflow.steps.types.trigger.types.names.subscription_payment_failed',
  [triggerTypes.digitalStoreOptIn]:
    'workflow.steps.types.trigger.types.names.digital_store_form_subscribed',
}

export const triggerTypesDescriptions = {
  [triggerTypes.tagAdded]: 'workflow.steps.types.trigger.description.tag_added',
  [triggerTypes.bookingCreated]:
    'workflow.steps.types.trigger.description.booking_added',
  [triggerTypes.tagRemoved]:
    'workflow.steps.types.trigger.description.tag_removed',
  [triggerTypes.funnelFormSubscribed]:
    'workflow.steps.types.trigger.description.form_subscribed',
  [triggerTypes.blogFormSubscribed]:
    'workflow.steps.types.trigger.description.form_subscribed',
  [triggerTypes.campaignCompleted]:
    'workflow.steps.types.trigger.description.campaign_completed',
  [triggerTypes.registeredToWebinar]:
    'workflow.steps.types.trigger.description.registered_to_webinar',
  [triggerTypes.enrolledInCourse]:
    'workflow.steps.types.trigger.description.enrolled_in_course',
  [triggerTypes.enrolledInCourseBundle]:
    'workflow.steps.types.trigger.description.enrolled_in_course_bundle',
  [triggerTypes.enrolledInCommunity]:
    'workflow.steps.types.trigger.description.enrolled_in_community',
  [triggerTypes.newSale]: 'workflow.steps.types.trigger.description.new_sale',
  [triggerTypes.saleCanceled]:
    'workflow.steps.types.trigger.description.sale_canceled',
  [triggerTypes.emailLinkClicked]:
    'workflow.steps.types.trigger.description.email_link_clicked',
  [triggerTypes.pageVisited]:
    'workflow.steps.types.trigger.description.page_visited',
  [triggerTypes.courseCompleted]:
    'workflow.steps.types.trigger.description.course_completed',
  [triggerTypes.moduleCompleted]:
    'workflow.steps.types.trigger.description.module_completed',
  [triggerTypes.lectureCompleted]:
    'workflow.steps.types.trigger.description.lecture_completed',
  [triggerTypes.emailOpened]:
    'workflow.steps.types.trigger.description.email_opened',
  [triggerTypes.subscriptionPaymentFailed]:
    'workflow.steps.types.trigger.description.subscription_payment_failed',
  [triggerTypes.digitalStoreOptIn]:
    'workflow.steps.types.trigger.description.digital_store_form_subscribed',
}
